import React, { useEffect } from "react";
import NavOverlay from "../NavOverlay";
import Layout from "../containers/Layout";
import InfoDropdown from "./InfoDropdown";
import ProfileInformation from "./ProfileInformation";

export default function Home({ variant }) {
  useEffect(() => {
    console.log("app mounted");
  }, []);

  return (
    <Layout variant={variant}>
      <ProfileInformation />
      {false && <InfoDropdown />}
      <NavOverlay />
    </Layout>
  );
}
