import React, { useState } from "react";
import {
  GitHub,
  LinkedIn,
  Email,
  Lightbulb,
} from "@mui/icons-material";
import { ReactComponent as BlueSky } from "../assets/bsky-logo.svg";
import { analytics } from "../containers/App";
import { logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";
import { SvgIcon } from "@mui/material";

const themes = ["", "brutalist", "nineties"];

function SocialLinks() {
  const navigate = useNavigate();
  const [showToggleTheme, setShowToggleTheme] = useState(false);

  const handleSocialClick = (type) => {
    logEvent(analytics, "social_click", {
      type,
    });
  };

  const toggleTheme = () => {
    handleSocialClick("change_theme");

    const randomIndex = Math.floor(Math.random() * themes.length);
    navigate(`/${themes[randomIndex]}`);
  };

  return (
    <section
      className="social-links"
      onDoubleClick={() => setShowToggleTheme(true)}
    >
      <div
        className="hover-return"
        onClick={() => handleSocialClick("bluesky")}
      >
        <a
          href="https://bsky.app/profile/jordankline.com"
          target="_blank"
          title="Bluesky"
          rel="noopener noreferrer"
        >
          <SvgIcon component={() => <BlueSky style={{ transform: "scale(0.55)" }} />} />
        </a>
      </div>
      <div className="hover-return" onClick={() => handleSocialClick("github")}>
        <a
          href="https://github.com/jordaninthewind"
          className="hover-return"
          target="_blank"
          title="Github"
          rel="noopener noreferrer"
        >
          <GitHub fontSize="large" />
        </a>
      </div>
      <div
        className="hover-return"
        onClick={() => handleSocialClick("linkedin")}
      >
        <a
          href="https://linkedin.com/in/jordan-kline"
          className="hover-return"
          target="_blank"
          title="Linkedin"
          rel="noopener noreferrer"
        >
          <LinkedIn fontSize="large" />
        </a>
      </div>
      <div className="hover-return" onClick={() => handleSocialClick("email")}>
        <a
          href="mailto:jordan.kline@gmail.com"
          className="hover-return"
          title="E-mail"
          rel="noopener noreferrer"
        >
          <Email fontSize="large" />
        </a>
      </div>
      {showToggleTheme && (
        <div className="hover-return" onClick={toggleTheme} role="button">
          <Lightbulb fontSize="large" />
        </div>
      )}
    </section>
  );
}

export default SocialLinks;
